/* eslint-disable */
import React from 'react';
import '../../components/buttons/mainBtn.css';
import './startPage.css';
import { Trans, useTranslation } from 'react-i18next';
import Modal from '../../components/modal/Modal';
import { getClientId, saveClientId } from '../../hookServices/clientId';
import { Button } from '../../shared/ui';
import { useGetBalanceQuery } from '../../redux/api';
import ReactGA from 'react-ga';

export default function PayOrStartModal({
  stationNumber,
  kwt,
  costPerKwt,
  setModalOpen,
  modalOpen,
  nextModalOpen,
  isTwentyFourHour,
  openPaymentLink,
  setError,
  autoStart,
}) {
  const { t } = useTranslation();

  const { data: freshBalance } = useGetBalanceQuery(getClientId());

  function storeClientIdAndSwitchModal() {
    const lastKwt = Number(localStorage.getItem('lastKwt'));
    localStorage.setItem('lastKwt', String(kwt));

    saveClientId();
    setModalOpen(false);
    if (!isTwentyFourHour) {
      nextModalOpen(true);
    } else {
      openPaymentLink(stationNumber, lastKwt, autoStart, setError);
      ReactGA.event({
        category: `start_${kwt}_kWt`,
        action: `start_${kwt}_kWt`,
      });
    }
  }

  return (
    <Modal setModalOpen={setModalOpen} modalOpen={modalOpen}>
      {parseFloat(freshBalance) >= parseFloat(costPerKwt * kwt) ? (
        <div>
          <h1 className="delayedStartWarning">
            {t('beforeStartInfo')} {kwt} {t('energyKwh')}?
          </h1>
          <br />
          <Button
            variant="outline"
            style={{
              display: 'flex',
              gap: '6px',
              margin: 'auto',
              width: '86%',
            }}
            onClick={() => storeClientIdAndSwitchModal()}
          >
            <span
              style={{
                fontSize: '24px',
                fontWeight: '300',
                letterSpacing: '2px',
              }}
            >
              {t('historyPage.started')}
            </span>
          </Button>
        </div>
      ) : (
        <div>
          <h1 className="delayedStartWarning">
            {t('beforePayInfoKwh')} {kwt} {t('energyKwh')}?
            <br />
            <br />
            {t('beforePayInfo')}
          </h1>
          <br />
          <Button
            variant="outline"
            style={{
              display: 'flex',
              gap: '6px',
              margin: 'auto',
              width: '86%',
            }}
            onClick={() => storeClientIdAndSwitchModal()}
          >
            <span
              style={{
                fontSize: '24px',
                fontWeight: '300',
                letterSpacing: '2px',
              }}
            >
              {t('btnPay')}
            </span>
          </Button>
        </div>
      )}
    </Modal>
  );
}

/* eslint-disable */
import { Navigate, useOutletContext } from 'react-router-dom';
import ErrorPage from '../../errorPage/ErrorPage';
import { Trans, useTranslation } from 'react-i18next';
import Spinner from '../../../components/loaders/globalSpinner/Spinner';
import { useGetStationStatusQuery } from '../../../redux/api';

export default function PrivateRoute({ children, fingerPrint }) {
  const { station, stationNumber, isFetchingStation, isLoadingStation, isErrorStation } = useOutletContext();

  const {
    data: stationStatus,
    isLoading: isLoadingStationStatus,
    isFetching: isFetchingStationStatus,
    isError: isErrorStationStatus,
  } = useGetStationStatusQuery({ stationNumber, fingerPrint });

  const { t } = useTranslation();

  if (isLoadingStationStatus || isFetchingStationStatus || isLoadingStation || isFetchingStation) {
    return <Spinner />;
  } else if (stationStatus?.lastJob?.state === 'IN_PROGRESS' || stationStatus?.lastJob?.state === 'WAITING') {
    return <Navigate to={`/charging?station=${stationNumber}`} />;
  } else if (isErrorStationStatus || isErrorStation) {
    return <ErrorPage errorHeader={t('errorDevHeader')} errorBody={t('errorDevBody')} />;
  } else if (station?.online === false) {
    return (
      <ErrorPage
        errorHeader={<Trans i18nKey="errorOfflineHeader">Station #{{ stationNumber }} is offline:(</Trans>}
        errorBody={t('errorOfflineBody')}
      />
    );
  } else if (
    stationStatus?.lastJob?.state === 'DONE' ||
    stationStatus?.lastJob?.state === 'FAILED' ||
    stationStatus?.lastJobPresented === false ||
    station?.online === false
  ) {
    return children;
  }
}

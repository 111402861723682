/* eslint-disable */
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { HOST, PORT } from '../environment';
import { getClientAgent, getClientId } from '../hookServices/clientId';

export const api = createApi({
  reducerPath: 'api',
  baseQuery: fetchBaseQuery({ baseUrl: `${HOST}:${PORT}` }),
  endpoints: builder => ({
    deviceStart: builder.query({
      query: ({ stationNumber }) => ({
        url: `/api/device/v2/start?clientUiId=${getClientId()}`,
        method: 'post',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          station_number: stationNumber,
        }),
      }),
      keepUnusedDataFor: 20,
    }),
    //todo test properly autoStart=false on iphone
    generateCheckoutLinkKwt: builder.query({
      query: ({ stationNumber, kwt, autoStart }) =>
        `/api/order/generateCheckoutLinkKwt?station_number=${stationNumber}&kwt=${Number(
          kwt
        )}&clientUiId=${getClientId()}&clientUiAgent=${getClientAgent()}&autoStart=${autoStart}`,
      keepUnusedDataFor: 1,
    }),
    getBloodyLitersSaved: builder.query({
      query: () => `api/device/v2/getBloodyLitersSaved`,
      keepUnusedDataFor: 1,
    }),
    getInProgressOrdersCount: builder.query({
      query: () => `/api/order/getInProgressOrdersCount?clientUiId=${getClientId()}`,
      keepUnusedDataFor: 1,
    }),

    getBalance: builder.query({
      query: () => `api/balance/getBalanceCents?clientUiId=${getClientId()}`,
      keepUnusedDataFor: 1,
    }),
    getNearestStation: builder.query({
      query: ({ lat, lon, top, distanceM }) =>
        `api/device/v2/station/nearest?lat=${lat}&lon=${lon}&top=${top}&distance_m=${distanceM}&clientUiId=${getClientId()}`,
      keepUnusedDataFor: 1,
    }),
    getKm220Ports: builder.query({
      query: ({ acdc }) => `api/device/v2/station/all?clientUiId=${getClientId()}&acdc=${acdc}`,
      keepUnusedDataFor: 1,
    }),
    getTokaPorts: builder.query({
      query: ({ acdc }) => `api/roaming/getTokaStations?clientUiId=${getClientId()}&acdc=${acdc}`,
      keepUnusedDataFor: 1,
    }),
    getUgvPorts: builder.query({
      query: ({ acdc }) => `api/roaming/getUgvStations?clientUiId=${getClientId()}&acdc=${acdc}`,
      keepUnusedDataFor: 1,
    }),
    getEcofactorPorts: builder.query({
      query: () => `api/roaming/getEcofactorStations?clientUiId=${getClientId()}`,
      keepUnusedDataFor: 1,
    }),
    getG2YPorts: builder.query({
      query: () => `api/roaming/getG2YStations?clientUiId=${getClientId()}`,
      keepUnusedDataFor: 1,
    }),
    getHistoryData: builder.query({
      query: () => `api/v2/history/getHistoryByClientUiId?clientUiId=${getClientId()}`,
      keepUnusedDataFor: 1,
    }),
    getDeviceStation: builder.query({
      query: ({ stationNumber, lat, lon }) =>
        `/api/device/v2/station?station_number=${stationNumber}&lat=${lat}&lon=${lon}&clientUiId=${getClientId()}`,
      keepUnusedDataFor: 1,
    }),
    getStationStatus: builder.query({
      query: ({ stationNumber }) =>
        `/api/device/v2/station/status?station_number=${stationNumber}&clientUiId=${getClientId()}`,
      keepUnusedDataFor: 1,
    }),
    getStopProcess: builder.query({
      query: () => ({
        url: '/api/device/v2/stop',
        method: 'patch',
        headers: {
          'Content-type': 'application/json',
        },
        body: JSON.stringify(clientId),
      }),
    }),
    getUserMe: builder.query({
      query: ({ userToken }) => ({
        url: `/user/me?clientUiId=${getClientId()}`,
        headers: {
          'X-Auth-Token': userToken,
        },
      }),
    }),
    getB2bStations: builder.query({
      query: ({ userToken }) => ({
        url: `/b2b/stations?clientUiId=${getClientId()}`,
        headers: {
          'X-Auth-Token': userToken,
        },
      }),
      transformResponse: response => ({
        stations: response
          ?.map(el => el.number)
          .sort((a, b) => a - b)
          .map(n => {
            return { label: n, value: n };
          }),
      }),
    }),
    getB2bReport: builder.query({
      query: ({ userToken, months, station }) => ({
        url: `/b2b/getReport?clientUiId=${getClientId()}`,
        method: 'post',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'X-Auth-Token': userToken,
        },
        body: JSON.stringify({
          station_numbers: station,
          'months_yyyy-mm': months,
        }),
      }),
    }),
    userLogout: builder.query({
      query: ({ userToken }) => ({
        url: `/user/logout?clientUiId=${getClientId()}`,
        method: 'post',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'X-Auth-Token': userToken,
        },
      }),
    }),
  }),
});

export const {
  useGetNearestStationQuery,
  useGetBloodyLitersSavedQuery,
  useGetBalanceQuery,
  useGetInProgressOrdersCountQuery,
  useGetKm220PortsQuery,
  useGetTokaPortsQuery,
  useGetUgvPortsQuery,
  useGetEcofactorPortsQuery,
  useGetG2YPortsQuery,
  useGetDeviceStationQuery,
  useGetStationStatusQuery,
  useGetUserMeQuery,
  useGetB2bStationsQuery,
  useGetB2bReportQuery,
  useGetHistoryDataQuery,
  useGetStopProcessQuery,
} = api;

export default {
  ua: {
    portNote_2: 'розетка або тайп 2 кабель',
    portNote_10: 'висить біля гаража # 14',
    portNote_39: 'струм до 25 А',
    portNote_40: 'струм до 25 А',
    portNote_41: 'струм до 25 А',
    portNote_11: 'струм до 16 А',
    portNote_31: 'струм до 16 А',
    portNote_33: 'струм до 16 А',
    portNote_35: 'струм до 16 А',
    portNote_37: 'струм до 16 А',
    portNote_70: 'струм до 10 А',
    portNote_6: 'струм до 25 А',
    portNote_5: 'струм до 25 А',
    portNote_211: '[Chi New Energy]',
    portNote_212: '[Chi New Energy]',
    portNote_213: '[Chi New Energy]',
    portNote_221: '[Chi New Energy]',
    portNote_222: '[Chi New Energy]',
    portNote_223: '[Chi New Energy]',
  },
  ru: {
    portNote_2: 'розетка або тайп 2 кабель',
  },
  en: {
    portNote_2: 'euro plug or Type 2 cable',
  },
};

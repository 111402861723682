/* eslint-disable */
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import styles from './StationCard.module.css';
import { Trans, useTranslation } from 'react-i18next';
import { CardLink, HomeCard, ModalTextColorLink } from '../../components/globalStyles';
import Timer from '../../components/timer/Timer';
import StationNumber from '../../components/stationNumber/StationNumber';
import ChargingProgress from '../../components/chargingProgress/ChargingProgress';
import { LoadingTime } from '../../components/loaders/localLoading/LocalLoading';
import { DistanceKm } from '../../components/DistanceKm';
import { getDate, plugTypeMaping } from '../../utils/utils';

import { Card, Button, Icon } from '@ui';

function StationCardStatus({ number, isFetching, online, job, t, openFrom, timer, over, setOver }) {
  const timerActive = online && (job?.state === 'WAITING' || job !== null);
  const offline = !online;
  const done = job?.state === 'DONE' || job === null;
  const inProgress = job?.state === 'IN_PROGRESS';
  const waiting = job?.state === 'WAITING';

  return (
    <div id={`stationCardStatus${number}Box`}>
      {isFetching ? (
        <LoadingTime />
      ) : (
        <>
          {timerActive && waiting && !over && (
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              {timer && (
                <Timer
                  hours={Number(timer[0])}
                  minutes={Number(timer[1])}
                  seconds={Number(timer[2])}
                  fontSize="clamp(0.938rem, -1.688rem + 11.67vw, 1.375rem)"
                  margin="0"
                  style={{ fontFamily: 'var(--font-family)' }}
                  over={over}
                  setOver={setOver}
                />
              )}
              <span
                style={{
                  fontSize: 'clamp(0.938rem, -1.688rem + 11.67vw, 1.375rem)',
                  fontWeight: '400',
                  letterSpacing: '1.2px',
                  verticalAlign: 'sub',
                }}
              >
                <Trans i18nKey={'startAt'}>Start at {{ openFrom }}</Trans>
              </span>
            </div>
          )}
          {waiting && offline && (
            <>
              <span
                style={{
                  fontSize: 'clamp(0.938rem, -1.688rem + 11.67vw, 1.375rem)',
                  fontWeight: '400',
                  letterSpacing: '1.2px',
                  verticalAlign: 'sub',
                }}
              >
                {t('stationOffline')}
                <span style={{ fontSize: '10px', marginLeft: '10px' }}>
                  <Trans i18nKey={'startAt'}>Start at {{ openFrom }}</Trans>
                </span>
              </span>
            </>
          )}
          {done && offline && (
            <span
              style={{
                fontSize: 'clamp(0.938rem, -1.688rem + 11.67vw, 1.375rem)',
                fontWeight: '400',
                letterSpacing: '1.2px',
                verticalAlign: 'sub',
              }}
            >
              {t('stationOffline')}
            </span>
          )}
          {inProgress && offline && (
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <span
                style={{
                  fontSize: 'clamp(0.938rem, -1.688rem + 11.67vw, 1.375rem)',
                  fontWeight: '400',
                  letterSpacing: '1.2px',
                  verticalAlign: 'sub',
                }}
              >
                {t('stationOffline')}
              </span>
              <div
                style={{
                  position: 'absolute',
                  width: 'calc(100% - 40px)',
                  display: 'flex',
                  justifyContent: 'center',
                  height: '40px',
                  alignItems: 'end',
                  zIndex: '10',
                }}
              >
                <ChargingProgress
                  status={job}
                  online={online}
                  widthContainer={'clamp(65px, 18vw, 80px)'}
                  marginContainer={'0'}
                  widthDot={'15px'}
                  heightDot={`15px`}
                />
              </div>
            </div>
          )}
          {inProgress && !offline && !over && (
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              {timer && (
                <Timer
                  hours={Number(timer[0])}
                  minutes={Number(timer[1])}
                  seconds={Number(timer[2])}
                  fontSize="clamp(0.938rem, -1.688rem + 11.67vw, 1.375rem)"
                  margin="0"
                  style={{ fontFamily: 'var(--font-family)' }}
                  over={over}
                  setOver={setOver}
                />
              )}
              <div
                style={{
                  position: 'absolute',
                  width: 'calc(100% - 40px)',
                  display: 'flex',
                  justifyContent: 'center',
                  height: '40px',
                  alignItems: 'end',
                  zIndex: '10',
                }}
              >
                <ChargingProgress
                  status={job}
                  online={online}
                  widthContainer={'clamp(65px, 18vw, 80px)'}
                  marginContainer={'0'}
                  widthDot={'15px'}
                  heightDot={`15px`}
                />
              </div>
            </div>
          )}
          {(done || over) && !offline && (
            <span
              style={{
                fontSize: 'clamp(0.938rem, -1.688rem + 11.67vw, 1.375rem)',
                fontWeight: '400',
                letterSpacing: '1.2px',
                verticalAlign: 'sub',
              }}
            >
              {t('readyForUse')}
            </span>
          )}
        </>
      )}
    </div>
  );
}

export const StationCard = ({ station, isFetching }) => {
  const { job, costPerKwt, number, online, latitude, longitude, distanceMeters, plugshareLink, plugType } = station;

  const [over, setOver] = useState(false);
  const [timer, setTimer] = useState(null);

  const isTwentyFourHour = station?.openFrom === '00:00:00' && station?.closedFrom === '00:00:00';
  const openFrom = station?.openFrom.slice(0, 5);
  const closedFrom = station?.closedFrom.slice(0, 5);

  const { t } = useTranslation();

  const stationMapLink = `https://www.google.com/maps/dir//${station?.latitude},${station?.longitude}/@${station?.latitude},${station?.longitude},12z`;

  useEffect(() => {
    if (job !== null) {
      if (job.state === 'WAITING') {
        setTimer(new Date(job?.leftTimeToStartS * 1000).toISOString().slice(11, 19).match(/\d+/g));
      }
      if (job.state === 'IN_PROGRESS') {
        setTimer(getDate(job.leftS));
      }
    }
    // eslint-disable-next-line
  }, [job, job?.state]);

  return (
    <Card
      active={job?.state === 'IN_PROGRESS' && online && !over}
      style={{
        // height: '145px',
        width: '100%',
        margin: '15px 0',
        display: 'flex',
        justifyContent: 'space-between',
        padding: '16px',
        fontWeight: '200',
        flexFlow: 'row',
        position: 'relative',
      }}
    >
      {/* <CardLink
          id={`stationCard${number}Component`}
          className={styles.linkToStation}
          to={
            job?.state === 'IN_PROGRESS' || job?.state === 'WAITING'
              ? `/charging?station=${number}`
              : `/start?station=${number}`
          }
        >
          <HomeCard id={`stationCard${number}Box`} className={styles.container}>
            <div
              id={`stationCardStationNumber${number}Box`}
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                flexWrap: 'wrap',
              }}
            >
              <div>
                <StationNumber
                  station={number}
                  costPerKwt={costPerKwt}
                  plugType={plugType}
                  justifyContent="justify-content-start"
                />
                {!isTwentyFourHour && (
                  <p style={{ marginBottom: '5px' }} className={styles.costText}>
                    {openFrom} — {closedFrom}
                  </p>
                )}
              </div>

              <div style={{ textAlign: 'right' }}>
                {latitude && longitude && (
                  <div className="d-flex justify-content-end">
                    <DistanceKm distanceMeters={distanceMeters} latitude={latitude} longitude={longitude} />
                  </div>
                )}
                {plugshareLink && (
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'flex-end',
                      marginTop: '5px',
                    }}
                  >
                    <ModalTextColorLink
                      href={plugshareLink}
                      onClick={e => e.stopPropagation()}
                      style={{ textDecoration: 'none' }}
                    >
                      <div
                        style={{
                          padding: '7px',
                          backgroundColor: '#d2b8d4',
                          color: '#FAFAFA',
                          fontWeight: '600',
                          width: '90px',
                          height: '35px',
                          fontSize: '14px',
                          borderRadius: '11px',
                        }}
                      >
                        <p className="mb-0 align-self-center text-center">{t('feedbacks')}</p>
                      </div>
                    </ModalTextColorLink>
                  </div>
                )}
              </div>
            </div>
            <StationCardStatus
              number={number}
              isFetching={isFetching}
              online={online}
              job={job}
              t={t}
              openFrom={openFrom}
              timer={timer}
              over={over}
              setOver={setOver}
            />
          </HomeCard>
        </CardLink> */}
      <Link
        to={
          job?.state === 'IN_PROGRESS' || job?.state === 'WAITING'
            ? `/charging?station=${number}`
            : `/start?station=${number}`
        }
        style={{
          listStyle: 'none',
          textDecoration: 'none',
          color: '#fff',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          fontSize: '24px',
          fontWeight: '400',
          letterSpacing: '0.5px',
          width: '65%',
        }}
      >
        <div>
          <div style={{ height: '40%', fontSize: 'clamp(1.15rem, -0.824rem + 8.57vw, 1.5rem)' }}>
            <span style={{ verticalAlign: 'top', lineHeight: '20px', letterSpacing: '1.4px' }}>
              {t('station')} {number}
              <span style={{ marginInline: '0.3rem' }}>{'>>'}</span>
              {plugTypeMaping[station?.plugType]}
              {/* {t('station')} <span style={{ fontWeight: '700', marginInline: '0.3rem' }}>{number}</span>{' '}
                <span style={{ marginInline: '0.3rem' }}>{'>>'}</span>
                {plugTypeMaping[station?.plugType]} */}
            </span>
          </div>
          <div>
            <span style={{ fontSize: 'clamp(0.875rem, -1.375rem + 10vw, 1.25rem)', marginBlock: 'min(10px, 5vw)' }}>
              {/* {station.costPerKwt} {t('costPerKwtAlt')} */}
              {station.costPerKwt/100} {t('costPerKwtH')}
            </span>
          </div>
        </div>

        <div>
          <StationCardStatus
            number={number}
            isFetching={isFetching}
            online={online}
            job={job}
            t={t}
            openFrom={openFrom}
            timer={timer}
            over={over}
            setOver={setOver}
          />
        </div>
      </Link>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          width: '35%',
          maxWidth: '180px',
        }}
      >
        <Button link={stationMapLink} style={{ width: 'auto', height: '45%', padding: '0 14px' }} variant="outline-alt">
          <Icon i="map" size="18px" />
          <span
            style={{
              fontSize: '12px',
              letterSpacing: '1px',
              marginLeft: '13px',
              textWrap: 'nowrap',
              whiteSpace: 'nowrap',
            }}
          >
            {`${(distanceMeters / 1000).toFixed(1)}  ${t('distanceKm')}`}
          </span>
        </Button>
        {plugshareLink && (
          <Button
            link={plugshareLink}
            style={{ width: 'auto', height: '45%', padding: '0 14px' }}
            variant="outline-alt"
          >
            <Icon i="feedback" size="18px" />
            <span
              style={{
                fontSize: '12px',
                letterSpacing: '1px',
                marginLeft: '13px',
                textWrap: 'nowrap',
                whiteSpace: 'nowrap',
              }}
            >
              {t('feedbacks')}
            </span>
          </Button>
        )}
      </div>
    </Card>
  );
};
